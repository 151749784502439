import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AdminBoard from './Boards/Admin'

import POflowDashboard from '../views/POflow'
import PDFoutput from '../views/POflow/PDFoutput'
import Install from './InstallPWA'
import Login from './Login'
import Register from './Register'
import Profile from './Profile'
import Comingsoon from './Comingsoon'

import '../App.css'

export default function Main({ updateMascot, edit }) {
  return (
    <div id="maincontent" className="bkg">
      <Switch>
        {/* <Route
          exact
          path={'/dashboard'}
          component={POflowDashboard}
          component={() => <POflowDashboard />}
        /> */}

        {/* <Route
          exact
          path={'/output'}
          component={POflowDashboard}
          component={() => <PDFoutput />}
        />

        {/* APP ROUTES */}
        <Route exact path="/admin" component={AdminBoard} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/login" component={Login} />

        {/* APP ROUTES */}
        {/* <Route exact path={['/']} component={Profile} /> */}
        <Route exact path={['/']} component={Comingsoon} />
        <Route exact path="/register" component={Register} /> */}
        
      </Switch>
    </div>
  )
}
