import React from 'react'
import './header.css'
// import { Link } from "react-router-dom";
// import mascot from '../../imgs/brand/swagocto.png'
// import Banner from '../../views/cms/Banner.cms'
import AllNav from '../Navbar'

// import InstallPWA from '../InstallPWA' <InstallPWA />

export default function Header({ editMode, currentUser, bannerType }) {
  // console.log(bannerType)

  return (
    <>
      {/* {bannerType && bannerType.map((t) => <Banner classes={t} key={t} type={t} />)} */}

      <div className="header-wrapper">
        <div className="header shadow">
          {/* <div className="header__bg overflow-no shadow"></div> */}
          {/* <div className="headerbase shadow"></div> */}

          <AllNav currentUser={currentUser} />
          {/* <div className="col align-self-end">
            <img className="dawg" src={mascot} alt="" />
          </div> */}
        </div>
      </div>
    </>
  )
}
