import React, { useRef, useState } from 'react'
import './style.css'

// import Header from '../components/Header/'
// const [bannerType, setBannerType] = useState(['all'])
// const [currentUser, setCurrentUser] = useState(null)
// <Header bannerType={bannerType} currentUser={{ currentUser, setCurrentUser }} />

import Logo from '../../imgs/rba/newlogoxl.png'


export default function Comingsoon() {
  

  return (
    <div className="container" >
      <div className="pt-5 mt-5 row">
        <img src={Logo} alt="ReelBig Logo" className="col-lg-6 col-md-8 col-sm-10 col-xs-11 ml-auto mr-auto mt-5 pt-5" />

      
        
      </div>
      <h5 className='center text-white upper'>fish on!</h5>
    </div>
  )
}
