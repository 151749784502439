import React, { useState } from 'react'

import MyModal from './Modal'

export default function ModalButton(props) {
  // console.log(props)
  const [modalShow, setModalShow] = useState(false)
  const toggleModal = () => {
    setModalShow(!modalShow)
  }

  const buttonOrIcon = () => {
    if (props.button) {
      return (
        <button
          onClick={toggleModal}
          value={props.status}
          className={`${props.btnClasses}`}
        >
          {props.btnText}
        </button>
      )
    }
    return (
      // <div className="btn-click" onClick={toggleModal}>
      //   <img
      //     className={props.imgClasses + ' mt-4 mr-4'}
      //     src={props.icon}
      //     alt={props.icon}
      //     width={props.width}
      //     height={props.height}
      //   />
      // </div>
      // <>
      <div
        className={
          props.imgClasses ? props.imgClasses + ' btn-click' : 'icon svg mt-3 mb-3'
        }
        // onClick={toggleModal}
        alt={props.alt || 'NO-ALT'}
        style={{
          backgroundImage: `url(${props.icon})`,
          width: props.width,
          height: props.height
        }}
      ></div>
    )
  }
  return (
    <div className={`${props.classes || ''}`} onClick={toggleModal}>
      {/* <div className="row">
        <div className="col-4"> */}
      {buttonOrIcon()}
      <MyModal show={modalShow} onHide={toggleModal} title={props.modalTitle}>
        {props.form({
          onHide: toggleModal,
          post: props.updateDeleteInfo || false,
          reason: props.reason,
          formLoad: modalShow,
          // status: props.status ? props.status : null
          status: props.status
          // name: props.name,
          // reqId: props.reqId,
          // item_name: props.itemName,
          // quantity: props.quantity,
          // unit_price: props.unit_price,
          // total_price: props.total_price,
          // description: props.description,
        })}
      </MyModal>
      {/* </div>
      </div> */}
    </div>
  )
}
