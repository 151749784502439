import React from 'react'
import '../App.css'
import loader from '../imgs/rba/bobbercler.gif'

export default function Loading() {
  return (
    // <div className="loading col mr-auto ml-auto">
    <div className="loading">
      <img alt="loading icon" className="dawgif" src={loader} />
      {/* <img
        alt="loading icon"
        className="col d-flex align-items-center justify-content-center dawgif"
        src={loader}
      /> */}
    </div>
  )
}
